<template>
    <div class="match">
        <div class="match__wrapper">
            <MatchStatusComponent
                :portrait="data.portrait"
                :content="data.content"
            />
            <OrbitingLoader/>
            <FixedMenu />
        </div>
    </div>
</template>

<style lang="scss">

    .match {
        background-image: url('@/assets/images/light-bcg-big.png');
        padding: 0 23px;
        padding-top: 20px;
        height: 100vh;
        overflow: hidden;
        margin: 0 auto;
        padding-bottom: 135px;
        &__wrapper {
            max-width: 500px;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
</style>

<script>
// @ is an alias to /src
import MatchStatusComponent from '@/components/MatchStatusComponent.vue';
import OrbitingLoader from '@/components/OrbitingLoader.vue';
import FixedMenu from '@/components/FixedMenu.vue';


export default {
    data(){
        return{
            data: { portrait: require("@/assets/images/basia-chat.png"), content: '<p><span>Cześć Filip,</span></p><p> szukamy teraz dla Ciebie odpowiedniego partnera. <strong>Może to potrwać kilka minut...</strong></p>' }
        }
    },
    components: {
        MatchStatusComponent,
        OrbitingLoader,
        FixedMenu
    },
    methods: {
        
    },
}
</script>