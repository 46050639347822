import { createRouter, createWebHistory } from 'vue-router'
import WelcomeView from '../views/WelcomeView.vue'
import Register from '../views/Register.vue'
import HomeView from '../views/HomeView.vue'
import Login from '../views/Login.vue'
import CreateProfile from '../views/CreateProfile.vue'
import Questions from '../views/Questions.vue'
import Search from '../views/Search.vue'
import Match from '../views/Match.vue'
import Profile from '../views/Profile.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
        meta: {
            showNavigationBar: false
        }
    },
    {
        path: '/register',
        name: 'register',
        component: Register,
        meta: {
            showNavigationBar: false
        },
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            showNavigationBar: false
        },
    },
    {
        path: '/create-profile',
        name: 'createprofile',
        component: CreateProfile,
        meta: {
            showNavigationBar: false
        },
    },
    {
        path: '/questions',
        name: 'questions',
        component: Questions,
        meta: {
            showNavigationBar: false
        },
    },
    {
        path: '/search',
        name: 'search',
        component: Search,
        meta: {
            showNavigationBar: false
        },
    },
    {
        path: '/match',
        name: 'match',
        component: Match,
        meta: {
            showNavigationBar: false
        },
    },
    {
        path: '/profile',
        name: 'profile',
        component: Profile,
        meta: {
            showNavigationBar: false
        },
    }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
