<template>
  <div class="match-status">
    <div class="match-status__outer">
      <Transition name="width" mode="out-in">
        <div class="match-status__content-box" v-show="!loaderVisibility">
          <figure class="match-status__figure">
            <img :src="portrait" />
          </figure>
          <div class="match-status__content" v-html="content"></div>
        </div>
      </Transition>
    </div>
    <Transition name="fade" mode="in-out">
      <div class="lds-ellipsis" v-show="loaderVisibility">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </Transition>
  </div>
</template>

<style lang="scss">
@import "@/assets/scss/Nunito.css";
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/breakpoints.scss";
@import "@/assets/scss/containers.scss";
@import "@/assets/scss/global.scss";

.match-status {
  position: relative;
  &__content-box {
    overflow: hidden;
    min-width: 200px;
    display: flex;
    align-items: flex-end;
  }
  &__outer {
    min-height: 62px;
  }
  &__content {
    background-color: $grayRGBA;
    border-radius: 10px;
    padding: 12px 20px;
    margin-left: 6px;
    font-family: "NunitoLight";
    border-bottom-left-radius: 0;

    p {
      font-size: 14px;
      line-height: 19px;
      font-weight: 300;
    }

    span {
      font-weight: 600;
    }

    strong {
      color: $darkBege;
      font-weight: 600;
    }
  }

  //loader animation

  //TRANSITION TO OPTIMIZE
  .fade-enter-active,
  .fade-leave-active {
    transition: 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

  .width-enter-active,
  .width-leave-active {
    transition: 0.5s ease;
  }

  .width-enter-from,
  .width-leave-to {
    scale: 0;
  }
  //TRANSITION TO OPTIMIZE

  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    position: absolute;
    left: 50%;
    bottom: -15px;
    transform: translateX(-50%);
  }

  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: $pink;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }

  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }

  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }

  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }

  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }

  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }

    100% {
      transform: scale(1);
    }
  }

  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }

    100% {
      transform: scale(0);
    }
  }

  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }

    100% {
      transform: translate(24px, 0);
    }
  }
}
</style>

<script>
// @ is an alias to /src
// import Hero from '@/components/Hero.vue'

export default {
  data() {
    return {
      loaderVisibility: true,
    };
  },
  props: {
    portrait: String,
    content: String,
  },
  mounted() {
    setTimeout(() => {
      this.loaderVisibility = !this.loaderVisibility;
    }, 1500);
  },
};
</script>
