<template>
    <div class="input">
      <label>{{ pole }}</label>
      <input 
        class="input__content" 
        :type="type" 
        :name="pole" 
        :id="pole" 
        @focus="onFocus" 
        @blur="onBlur" 
        @change="onChange"
        v-model="val"
      />
    </div>
  </template>
  
  <script>
export default {
  props: {
    pole: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'text',
    },
  },
  data() {
    return {
        val: '',
    };
  },
  watch: {
    val(newValue) {
      this.$emit('update:val', newValue)
    }
  },
  methods: {
    onFocus(event) {
        event.target.previousElementSibling.classList.add('input--active');
    },
    onBlur(event) {
      const input = event.target;
      if (input.value.length > 0) {
        input.previousElementSibling.classList.add('input--active');
      } else {
        input.previousElementSibling.classList.remove('input--active');
      }
    },
    onChange(event) {
      const input = event.target;
      if (input.value.length > 0) {
        input.previousElementSibling.classList.add('input--active');
      } else {
        input.previousElementSibling.classList.remove('input--active');
      }
    },
  },
  mounted() {
    const input = this.$el.querySelector('input__content');
    if (input) {
      if (input.value.length > 0) {
        input.previousElementSibling.classList.add('input--active');
      } else {
        input.previousElementSibling.classList.remove('input--active');
      }
    }
  },
};
</script>

  <style lang="scss" scoped>
  @import "@/assets/scss/variables.scss";
  @import "@/assets/scss/breakpoints.scss";
  @import "@/assets/scss/containers.scss";
  @import "@/assets/scss/global.scss";
  @import "@/assets/scss/Nunito.css";

    .input {
        margin-bottom: 12px;
        width: 100%;
        position: relative;
        label {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 11px;
            font-weight: 400;
            font-size: 18px;
            color: $black1;
            transition: .3s;
            padding: 0 8px;
            border-radius: 3px;
            background-color: $gray;
            font-family: "NunitoRegular";
        }
        input {
            border: none;
            outline: none;
            width: 100%;
            background: $gray;
            border-radius: 12px;
            padding: 19px 0 19px 19px;
            font-weight: 400;
            font-size: 18px;
            line-height: 25px;
            color: $black1;
        }
        &--active {
            left: 19px !important;
            top: 6% !important;
            font-size: 13px !important;
            color: $pink !important;
        }
    }

  </style>
  