<template>
    <div class="profile-content">
        <div class="profile-content__wrapper">
            <h1 class="profile-content__title">
                {{ data.title }}
            </h1>
            <div class="profile-content__name-box">
                <figure>
                    <img :src="data.portrait">
                </figure>
                <div class="profile-content__personal">
                    <p>{{ data.name }}, {{ data.age }}</p>
                    <div class="profile-content__city">
                        <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_75_2066)">
                            <path d="M5.86663 13.9998C5.56805 13.9447 5.34367 13.7679 5.12519 13.5736C4.26134 12.8049 3.37876 12.0557 2.59015 11.2088C1.78074 10.3393 1.05179 9.41092 0.571485 8.32048C-0.418602 6.07382 -0.117935 3.98304 1.4402 2.09198C2.38589 0.944325 3.6385 0.260872 5.12346 0.0638868C7.26039 -0.219772 9.07687 0.43696 10.5181 2.02826C11.5117 3.12555 11.9858 4.43422 12 5.90322C12.008 6.74631 11.7964 7.54419 11.4479 8.30883C10.9537 9.39311 10.2393 10.3287 9.42991 11.1982C8.58478 12.106 7.64081 12.9104 6.70344 13.721C6.5512 13.8525 6.33203 13.9087 6.14407 13.9995H5.86733L5.86663 13.9998ZM0.84649 5.80901C0.814932 6.29445 0.957463 7.05224 1.1423 7.54316C1.53279 8.57879 2.15701 9.46882 2.88042 10.2986C3.77479 11.3246 4.81863 12.1965 5.83507 13.0971C5.96408 13.2112 6.04696 13.2122 6.17493 13.0964C6.92296 12.4191 7.68624 11.7569 8.41069 11.0557C9.32032 10.1745 10.1148 9.19886 10.6548 8.04573C11.0012 7.30541 11.202 6.53357 11.1607 5.70657C11.0942 4.38249 10.6014 3.23723 9.64839 2.31466C8.35382 1.06149 6.78771 0.600714 5.00208 0.918289C4.16632 1.06697 3.41864 1.42634 2.75973 1.95597C2.05956 2.51918 1.53834 3.21497 1.20542 4.04641C0.972375 4.62846 0.850999 5.2321 0.846837 5.80935L0.84649 5.80901Z" fill="#D2C7B3"/>
                            <path d="M3.36524 5.69269C3.36836 4.25213 4.55161 3.08426 6.00675 3.08563C7.46223 3.087 8.64652 4.25932 8.64444 5.69612C8.64235 7.13325 7.45286 8.30866 6.00293 8.30626C4.55022 8.30386 3.36212 7.1264 3.36524 5.69235V5.69269ZM7.81387 5.69201C7.8111 4.70194 6.99891 3.90338 5.99842 3.90646C5.00417 3.90955 4.19962 4.70571 4.19546 5.69098C4.1913 6.67625 5.00695 7.48577 6.00397 7.48646C7.0003 7.48714 7.81665 6.67796 7.81352 5.69235L7.81387 5.69201Z" fill="#D2C7B3"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_75_2066">
                            <rect width="12" height="14" fill="white"/>
                            </clipPath>
                            </defs>
                        </svg>
                        <span>{{ data.city }}</span>
                    </div>
                </div>
            </div>
            <div class="profile-content__description" v-html="data.description"></div>
            <div class="profile-content__likes">
                <div class="profile-content__like" v-for="(like, index) in data.likes">
                    <div v-html="like.icon"></div>
                    <span>{{ like.text }}</span>
                </div>
            </div>
            <Button :isPink="true" text="Poznaj go bliżej" class="main-button" />
        </div>
    </div>
</template>

<style lang="scss">
    @import "@/assets/scss/Nunito.css";
    @import "@/assets/scss/variables.scss";
    @import "@/assets/scss/breakpoints.scss";
    @import "@/assets/scss/containers.scss";
    @import "@/assets/scss/global.scss";

    .profile-content {
        // padding-bottom: 130px;
        color: $black1;
        font-family: 'NunitoLight', sans-serif;

        &__title {
            font-family: "DMSerifDisplay-Regular", serif;
            font-weight: 400;
            font-size: 26px;
            line-height: 36px;
            margin-top: 25px;
            margin-bottom: 33px;
        }
        &__name-box {
            display: flex;
            align-items: center;
            figure {
                border-radius: 100%;
                overflow: hidden;
                position: relative;
                max-width: 82px;
                max-height: 82px;
                width: 100%;
                margin-right: 18px;
                border: 2px solid $bege;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        &__personal {
            p {
                font-size: 22px;
                line-height: 30px;
                font-weight: 600;
                display: inline-block;
                margin-bottom: 5px;
            }
        }
        &__city {
            display: flex;
            align-items: center;
            svg {
                margin-right: 9px;
            }
            span {
                font-weight: 300;
                font-size: 16px;
                line-height: 22px;
            }
        }
        &__description {
            font-size: 16px;
            line-height: 22px;
            font-weight: 300;
            margin-top: 32px;
            p {
                margin-bottom: 20px;
            }
        }
        &__like {
            display: flex;
            align-items: center;
            margin-bottom: 32px;
            &:not(:last-child) {
                margin-bottom: 10px;

            }
            svg {
                margin-right: 11px;
            }
            span {
                font-size: 16px;
                font-weight: 300;
                line-height: 22px;
            }
        }
    }
</style>

<script>
import Button from "@/components/atoms/Button.vue";

// @ is an alias to /src



export default {
    data(){
        return{
        }
    },
    components: {
        Button
    },
    methods: {
        
    },
    props: {
        data: Object,
    }
}
</script>