<template>
    <div class="question">
        <div class="question__single">
            <span>{{ question.text }}</span>
        </div>
        <div class="question__answers">
            <button 
                v-for="(answer, index) in question.answers" 
                :key="index"
                :class="{ 'question__checked': question.checkedAnswer === index }"
                @click="selectAnswer(index)"
            >{{ answer }}</button>
        </div>
    </div>   
</template>

<script>
export default {
    data(){
        return{
            checked: false
        }
    },
    props: {
        question: Object
    },
    methods:{
        selectAnswer(index) {
            // eslint-disable-next-line vue/no-mutating-props
            this.question.checkedAnswer = index;
            setTimeout(() => {
                this.$emit('answer-selected', index);
            }, 1500);
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/breakpoints.scss";
@import "@/assets/scss/containers.scss";
@import "@/assets/scss/global.scss";
.question{
    margin: 0 20px;
    
    &__checked{
        border: 2px solid $bege;
        box-shadow: 0px 4px 11px $bege;
        color: $bege;
    }
    &__single{
        font-family: 'NunitoBold';
        padding: 78px 20px;
        font-weight: 700;
        font-size: 20px;
        background-color: $white;
        border-radius: 20px;
        text-align: center;
    }
    &__answers{
        margin-top: 59px;
    }

    button{
        padding: 10px 0;
        cursor: pointer;
        background-color: $white;
        border-radius: 12px;
        font-family: 'NunitoBold';
        margin-bottom: 12px;
        font-size: 18px;
        padding: 19px 10px;
        width: 100%;
        font-weight: 700;
    }
}
</style>