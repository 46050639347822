<template>
    <div id="circle-orbit-container">
        <!-- Circles closest to the central point -->
        <div id="inner-orbit">
            <div class="inner-orbit-cirlces"></div>
        </div>

        <!-- Circles second closest to the central point -->
        <div id="middle-orbit">
            <div class="middle-orbit-cirlces"></div>
        </div>

        <!-- Circles furthest away to the central point -->
        <div id="outer-orbit">
            <div class="outer-orbit-cirlces"></div>
        </div>
    </div>
</template>

<style lang="scss">
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/breakpoints.scss";
@import "@/assets/scss/containers.scss";
@import "@/assets/scss/global.scss";

/* ---------- Container for the orbiting circles animation ---------- */
#circle-orbit-container {
    position: relative;
    // top: 25px;
    // left: 25px;
    height: 300px;
    width: 300px;
    margin: 0 auto;
    margin-top: 63px;
}

/* ---------- Inner orbit - This is the circles closest to the central point ---------- */
#inner-orbit {
    position: absolute;
    top: 75px;
    left: 75px;
    width: 150px;
    height: 150px;
    border: 2px rgba(210, 199, 179, 0.26) solid;
    border-radius: 100%;
    // -webkit-animation: spin-right 10s linear infinite;
    // animation: spin-right 10s linear infinite;
    background-image: url('@/assets/images/filip-circle.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 100%;
        opacity: 0.5;
        transform: scale(0);
        animation: radar 2s ease infinite;
        background-color: $pink;
    }
}

/* ---------- Repeating styles for the inner orbiting circles ---------- */
.inner-orbit-cirlces {
    // position: absolute;
    // top: 62px;
    // left: -6px;
    // height: 10px;
    // width: 10px;
    // border-radius: 100%;
    // background-color: #9f98e6;
}

/* ---------- Middle orbit - This is the circles second closest to the central point ---------- */
#middle-orbit {
    position: absolute;
    top: 35px;
    left: 35px;
    width: 225px;
    height: 225px;
    border: 2px rgba(210, 199, 179, 0.26) solid;
    border-radius: 100%;
    -webkit-animation: spin-right 15s linear infinite;
    animation: spin-right 15s linear infinite;
}

/* ---------- Repeating styles for the inner orbiting circles ---------- */
.middle-orbit-cirlces {
    position: absolute;
    top: 25px;
    left: 17px;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    background-image: url('@/assets/images/basia-chat.png');
    background-position: center;
    background-size: contain;
    border: 2px solid $bege;
    -webkit-animation: spin-left 15s linear infinite;
    animation: spin-left 15s linear infinite;
    opacity: 0.5;
    background-size: cover;

}

/* ---------- Outer orbit - This is the circles furthest away from the central point ---------- */
#outer-orbit {
    position: absolute;
    top: 0;
    left: 0;
    width: 294px;
    height: 294px;
    border: 2px rgba(210, 199, 179, 0.26) solid;
    border-radius: 100%;
    -webkit-animation: spin-right 20s linear infinite;
    animation: spin-right 20s linear infinite;
}

/* ---------- Repeating styles for the outer orbiting circles ---------- */
.outer-orbit-cirlces {
    position: absolute;
    top: -20px;
    left: 125px;
    height: 40px;
    width: 40px;
    border-radius: 100%;
    background-image: url('@/assets/images/basia-chat.png');
    background-position: center;
    border: 2px solid $bege;
    -webkit-animation: spin-left 20s linear infinite;
    animation: spin-left 20s linear infinite;
    opacity: 0.5;
    background-size: cover;

}

/* ---------- Animation ---------- */
@keyframes radar {
    100% {
        transform: scale(2.5);
        opacity: 0;
    }
}
@keyframes spin-right {
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes spin-left {
    100% {
        -webkit-transform: rotate(-360deg);
        -moz-transform: rotate(-360deg);
        -ms-transform: rotate(-360deg);
        -o-transform: rotate(-360deg);
        transform: rotate(-360deg);
    }
}
</style>

<script>
// @ is an alias to /src

export default {
    data() {
        return {};
    },
    components: {},
    methods: {},
};
</script>
