<template>
    <transition name="fade" mode="out-in">
        <router-view/>
    </transition>
</template>

<style lang="scss">
@import "~vue-mobile-viewport-height/src/mobile-viewport-height.scss";
@import "@/assets/scss/Nunito.css";
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/breakpoints.scss";
@import "@/assets/scss/containers.scss";
@import "@/assets/scss/global.scss";

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}


 
.app {
  @include mobile-viewport-height();
}
</style>
