<template>
    <div class="home">
        <Hero/>
    </div>
</template>

<style lang="scss">
    .home {
        height: 100vh;
    }
</style>

<script>
// @ is an alias to /src
import Hero from '@/components/Hero.vue'


export default {
    name: 'HomeView',
    data(){
        return{
            
        }
    },
    components: {
        Hero
    },
    methods: {
        
    },
}
</script>
