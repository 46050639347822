import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
// import store from './store'
import debounce from 'lodash/debounce'
import MobileViewportHeight from 'vue-mobile-viewport-height'
 


const app = createApp(App);

app.use(
    MobileViewportHeight({
        Vue: app,
        debounce, // optional debounce function
    })
);  

app.use(router).mount('#app');

// import { createApp } from 'vue'
// import App from './App.vue'
// import './registerServiceWorker'
// import router from './router'
// import VueToasted from 'vue-toasted'

// const app = createApp(App)

// // używamy VueToasted
// app.use(VueToasted)

// app.use(router).mount('#app')
